.header-container {
  height: 16rem;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .header-container .header-logo {
    padding: 0rem 5rem; }
    .header-container .header-logo img {
      width: 80%; }
  .header-container .right-menu-container {
    padding: 0rem 5rem;
    display: flex; }
    .header-container .right-menu-container .menu-item {
      cursor: pointer;
      font-size: 2.44rem;
      padding: 0 2rem; }
      .header-container .right-menu-container .menu-item i {
        font-size: 2.44rem;
        font-weight: bold; }
    .header-container .right-menu-container .properties-icon-wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      color: #1569db; }
      .header-container .right-menu-container .properties-icon-wrapper .properties-icon {
        line-height: 0; }
    .header-container .right-menu-container .notification-bell-wrapper svg {
      font-size: 2.5rem;
      font-weight: bold; }
    .header-container .right-menu-container .notification-bell-wrapper img {
      width: 80%; }
  @media screen and (max-width: 800px) {
    .header-container .header-logo {
      padding: 0rem 0rem 0 5rem; }
      .header-container .header-logo img {
        width: 100%; } }
  @media screen and (min-width: 990px) {
    .header-container .mobile-menu {
      display: none; } }

.section-header {
  font-size: 3.6rem;
  font-weight: 600;
  color: #000;
  margin-bottom: 2rem; }

.form-label {
  font-size: 1.8rem;
  color: #000;
  margin-bottom: 1.7rem; }
  @media screen and (max-width: 700px) {
    .form-label {
      font-size: 2.8rem; } }
  @media screen and (min-width: 701px) and (max-width: 1199px) {
    .form-label {
      font-size: 2.6rem; } }

.input-wrapper .form-input {
  height: 5.5rem;
  width: 100%;
  font-size: 2rem;
  color: #000;
  border: 1px solid #bdbdbd;
  padding: 1rem 2.8rem;
  outline: 0;
  border-radius: 0.5rem; }
  @media screen and (max-width: 700px) {
    .input-wrapper .form-input {
      height: 8rem;
      font-size: 3rem; } }
  @media screen and (min-width: 700px) and (max-width: 1199px) {
    .input-wrapper .form-input {
      height: 8rem;
      font-size: 3rem; } }

.input-wrapper .light {
  background-color: #fff; }

.input-wrapper .grey {
  background-color: #f5f6fa; }

.input-wrapper .disabled {
  background-color: #efefef !important;
  opacity: 0.5; }

.form-area-input {
  height: 19rem;
  width: 100%;
  font-size: 2rem;
  background-color: #f5f6fa;
  color: #000;
  border: 1px solid #bdbdbd;
  padding: 1rem 2.8rem;
  outline: 0;
  border-radius: 0.5rem; }
  @media screen and (max-width: 800px) {
    .form-area-input {
      font-size: 3rem !important; } }

.light {
  background-color: #fff; }

.grey {
  background-color: #f5f6fa; }

.button-comp-wrapper .button {
  height: 6rem;
  width: 100%;
  font-size: 2rem;
  background-color: #ed303b;
  color: #fff;
  border: 1px solid #bdbdbd;
  padding: 1rem 2.8rem;
  outline: 0;
  border-radius: 1rem;
  opacity: 0.85; }
  @media screen and (max-width: 1199px) {
    .button-comp-wrapper .button {
      font-size: 3rem;
      height: 8rem;
      padding: 2rem 3.2rem; } }

.button-comp-wrapper .disabled {
  opacity: 0.6; }

.status-button {
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  padding: 0.5rem 1.4rem;
  outline: 0;
  border-radius: 0.6rem;
  opacity: 0.85;
  box-shadow: none;
  outline: 0; }

.available {
  background-color: #53b729;
  border: 1px solid #53b729; }

.occupied {
  background-color: #ed303b;
  border: 1px solid #ed303b; }

.select-box {
  width: 29rem;
  height: 5.5rem;
  background-color: #fff;
  border: none;
  outline: 0;
  margin-right: 1.5rem;
  font-size: 2rem;
  font-weight: bold;
  color: #000;
  padding: 0 1rem;
  border-radius: 1rem;
  margin-bottom: 2rem; }
  @media screen and (max-width: 600px) {
    .select-box {
      width: 100%;
      height: 8rem;
      font-size: 2.7rem; } }
  @media screen and (min-width: 600px) and (max-width: 992px) {
    .select-box {
      height: 8rem;
      font-size: 2.7rem; } }

.light {
  background-color: #fff; }

.grey {
  background-color: #f5f6fa; }

.ticket {
  margin-right: 2rem;
  padding: 2rem 0;
  border-bottom: 1px solid #bdbdbd;
  display: flex;
  justify-content: space-between; }
  .ticket .ticket-content .subject {
    font-size: 2.2rem;
    font-weight: bold;
    color: #000; }
    @media screen and (max-width: 992px) {
      .ticket .ticket-content .subject {
        font-size: 2.5rem; } }
  .ticket .ticket-content .description {
    font-size: 2rem;
    font-weight: bold;
    color: #9b9b9b; }
    @media screen and (max-width: 992px) {
      .ticket .ticket-content .description {
        font-size: 2.2rem; } }
  .ticket .ticket-status {
    font-size: 2.2rem;
    font-weight: bold;
    color: #0865de; }
    @media screen and (max-width: 992px) {
      .ticket .ticket-status {
        font-size: 2.4rem; } }

.kpi-container {
  padding: 7rem 3rem;
  background: #fe5f5f;
  width: 30rem;
  border-radius: 2.7rem;
  box-shadow: 2rem 4rem 2rem 0 rgba(0, 0, 0, 0.08);
  margin-right: 2rem; }
  .kpi-container .kpi-label {
    font-size: 2.2rem;
    font-weight: bold;
    color: #fff;
    margin-bottom: 2rem; }
  .kpi-container .kpi-value {
    font-size: 3rem;
    font-weight: bold;
    color: #fff; }
  @media screen and (max-width: 990px) {
    .kpi-container {
      width: 100%;
      margin-bottom: 4rem; } }

.doughnut-progress-container .pie {
  position: relative;
  width: 20rem;
  height: 20rem;
  border-radius: 50%;
  background-color: #fff;
  background-image: linear-gradient(to right, transparent 50%, currentColor 0);
  color: #f1bb35; }

.doughnut-progress-container .pie:before {
  content: '';
  display: block;
  margin-left: 50%;
  height: 100%;
  border-radius: 0 100% 100% 0 / 50%;
  background-color: inherit;
  transform-origin: left;
  transform: rotate(0turn);
  background-color: currentColor;
  animation: spin 0.75s cubic-bezier(0.52, 0.32, 0.76, 1.3) forwards; }

.doughnut-progress-container .pie-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85%;
  height: 85%;
  background-color: #fff;
  border-radius: 50%;
  font-size: 3.2rem;
  font-weight: 600;
  color: #0c3954; }

@keyframes spin {
  /* 0.25turn = 75%
     * 0.4turn = 90% */
  to {
    transform: rotate(0.4turn); } }

@keyframes bg {
  50% {
    background: currentColor; } }

.settl-area-chart-container {
  margin-right: 7rem;
  margin-top: 7rem; }
  .settl-area-chart-container .recharts-cartesian-axis-tick {
    font-size: 1.6rem; }
  @media screen and (max-width: 600px) {
    .settl-area-chart-container {
      margin-right: 0rem;
      margin-bottom: 5rem; }
      .settl-area-chart-container .recharts-cartesian-axis-tick {
        font-size: 2rem; } }

.properties-dropdown-container {
  max-height: 70vh;
  min-height: 20vh;
  overflow-y: scroll;
  position: absolute;
  right: 1rem;
  top: 16rem;
  z-index: 5;
  width: 40rem;
  padding: 7rem;
  box-shadow: -5px 5px 20px 0 #00000029;
  background-color: #fff;
  border-radius: 2.8rem; }
  .properties-dropdown-container .property-item {
    cursor: pointer;
    padding: 3rem 0;
    border-bottom: 1px solid #bdbdbd; }
    .properties-dropdown-container .property-item .property-name {
      font-size: 2.2rem;
      font-weight: bold;
      color: #000; }
    .properties-dropdown-container .property-item .property-address {
      font-size: 2.2rem;
      font-weight: bold;
      color: #9b9b9b; }
    .properties-dropdown-container .property-item .occupancy {
      font-size: 2.2rem;
      font-weight: bold;
      color: #0865de; }
  .properties-dropdown-container .close-button {
    position: absolute;
    font-size: 4rem;
    right: 2rem;
    top: 2.5rem; }

.notifications-container {
  max-height: 70vh;
  min-height: 20vh;
  overflow-y: scroll;
  position: absolute;
  right: 1rem;
  top: 16rem;
  z-index: 5;
  width: 40rem;
  padding: 7rem;
  box-shadow: -5px 5px 20px 0 #00000029;
  background-color: #fff;
  border-radius: 2.8rem; }
  .notifications-container .notification-item {
    padding: 3rem 0;
    border-bottom: 1px solid #bdbdbd; }
    .notifications-container .notification-item .notification-name {
      font-size: 2.2rem;
      font-weight: bold;
      color: #000; }
    .notifications-container .notification-item .notification-address {
      font-size: 2.2rem;
      font-weight: bold;
      color: #9b9b9b; }
    .notifications-container .notification-item .occupancy {
      font-size: 2.2rem;
      font-weight: bold;
      color: #0865de; }
  .notifications-container .close-button {
    position: absolute;
    font-size: 4rem;
    right: 2rem;
    top: 2.5rem; }

.tds-container .modal-content {
  border-radius: 1.5rem; }
  .tds-container .modal-content .modal-header .modal-title {
    padding: 2rem;
    font-size: 2.6rem;
    font-weight: 600;
    color: #000; }
  .tds-container .modal-content .modal-header .close {
    font-size: 4rem;
    box-shadow: none;
    outline: none; }
  .tds-container .modal-content .modal-body .certificate-container .certificate {
    text-decoration: none;
    list-style: none;
    padding: 1rem 5rem;
    font-size: 2.6rem;
    font-weight: 600; }
    .tds-container .modal-content .modal-body .certificate-container .certificate a {
      text-decoration: none; }
    .tds-container .modal-content .modal-body .certificate-container .certificate .cert-label {
      color: #000; }

.message {
  font-size: 2.2rem;
  color: #fff;
  border-radius: 0.4rem;
  position: relative; }
  .message .close-wrapper {
    position: absolute;
    right: 1rem;
    top: 0.5rem; }

.error-message {
  color: #fe5f5f;
  margin: 2rem 0 3rem 0; }

.success-message {
  background: rgba(83, 183, 41, 0.7);
  margin: 2rem 0 3rem 0;
  display: inline-block;
  padding: 2rem 8rem 2rem 2rem; }

.loader-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%; }
  .loader-container .loader-image {
    left: 0;
    text-align: center;
    gap: 25px;
    color: #0e73b7; }
    .loader-container .loader-image .loading-text {
      margin-top: 1rem;
      font-size: 2rem; }

.confirm-logout-container .modal-content {
  border-radius: 1.5rem; }
  .confirm-logout-container .modal-content .modal-header .modal-title {
    padding: 2rem;
    font-size: 2.2rem;
    font-weight: 600;
    color: #000; }
  .confirm-logout-container .modal-content .modal-header .close {
    font-size: 4rem;
    box-shadow: none;
    outline: none; }
  .confirm-logout-container .modal-content .modal-footer {
    padding: 2rem;
    border: none; }
    .confirm-logout-container .modal-content .modal-footer button {
      font-size: 1.75rem;
      padding: 0.5rem 2rem;
      border: none; }
    .confirm-logout-container .modal-content .modal-footer .btn-primary {
      background: #ed303b; }

.idle-timeout-container .modal-content {
  border-radius: 1.5rem; }
  .idle-timeout-container .modal-content .modal-header .modal-title {
    padding: 2rem;
    font-size: 2.2rem;
    font-weight: 600;
    color: #000; }
  .idle-timeout-container .modal-content .modal-header .close {
    font-size: 4rem;
    box-shadow: none;
    outline: none; }
  .idle-timeout-container .modal-content .modal-body {
    font-size: 1.6rem; }
  .idle-timeout-container .modal-content .modal-footer {
    padding: 2rem;
    border: none; }
    .idle-timeout-container .modal-content .modal-footer button {
      font-size: 1.75rem;
      padding: 0.5rem 2rem;
      border: none; }
    .idle-timeout-container .modal-content .modal-footer .btn-primary {
      background: #0865de; }

.login-container {
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative; }
  .login-container .login-header-container {
    height: 16rem;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .login-container .login-header-container .header-logo {
      padding: 2rem; }
  .login-container .login-form-container {
    position: absolute;
    border: 1px solid #707070;
    border-radius: 1.6rem;
    right: 16rem;
    top: 16rem;
    background: #fff;
    width: 56rem;
    padding: 8rem 3rem; }
    .login-container .login-form-container .resend-otp-link-wrapper {
      margin: 2rem 0 4rem 0;
      font-size: 1.8rem;
      display: flex; }
      .login-container .login-form-container .resend-otp-link-wrapper .resend-otp-link {
        font-size: 1.8rem;
        border: none;
        background: #fff;
        box-shadow: none;
        color: #0865de;
        font-weight: 400; }
  @media screen and (max-width: 700px) {
    .login-container .login-form-container {
      position: static;
      top: 0;
      left: 0;
      background: none;
      border: none;
      width: 100%; }
      .login-container .login-form-container .resend-otp-link-wrapper .resend-otp-link {
        background: transparent; } }
  @media screen and (min-width: 701px) and (max-width: 1199px) {
    .login-container .login-form-container {
      position: absolute;
      border: 1px solid #707070;
      border-radius: 1.6rem;
      right: 16rem;
      top: 16rem;
      background: #fff;
      width: 80rem;
      padding: 8rem 3rem; } }

.landing-tab-container {
  padding: 0px; }
  .landing-tab-container .landing-tab-wrapper .sidebar-container {
    position: absolute;
    width: 32rem;
    background-color: #0865de;
    height: calc(100vh - 16rem);
    border-radius: 5rem 0 0 5rem; }
    @media screen and (max-width: 992px) {
      .landing-tab-container .landing-tab-wrapper .sidebar-container {
        width: 38rem; } }
    .landing-tab-container .landing-tab-wrapper .sidebar-container ul {
      list-style: none;
      padding: 4rem 3.5rem; }
      .landing-tab-container .landing-tab-wrapper .sidebar-container ul li {
        padding: 2.7rem 0;
        font-size: 2rem; }
        @media screen and (max-width: 992px) {
          .landing-tab-container .landing-tab-wrapper .sidebar-container ul li {
            font-size: 2.5rem; } }
        .landing-tab-container .landing-tab-wrapper .sidebar-container ul li a {
          color: #fff;
          text-decoration: none;
          opacity: 0.7; }
          .landing-tab-container .landing-tab-wrapper .sidebar-container ul li a i {
            font-size: 3rem;
            vertical-align: bottom;
            padding-right: 1rem; }
            @media screen and (max-width: 992px) {
              .landing-tab-container .landing-tab-wrapper .sidebar-container ul li a i {
                font-size: 3.5rem; } }
      .landing-tab-container .landing-tab-wrapper .sidebar-container ul .logout-container {
        cursor: pointer;
        position: absolute;
        bottom: 10rem; }
        .landing-tab-container .landing-tab-wrapper .sidebar-container ul .logout-container .logout {
          font-size: 2.3rem;
          color: #ffadad; }
          .landing-tab-container .landing-tab-wrapper .sidebar-container ul .logout-container .logout svg {
            font-size: 4.7rem;
            padding-right: 1rem;
            color: #ffadad; }
    .landing-tab-container .landing-tab-wrapper .sidebar-container .active-tab a {
      font-weight: bold;
      opacity: 1; }
  .landing-tab-container .landing-tab-wrapper .page-content-wrapper {
    margin-left: 28rem;
    width: 100%; }
    .landing-tab-container .landing-tab-wrapper .page-content-wrapper .content {
      background-color: #f5f6fa;
      height: calc(100vh - 16rem);
      border-radius: 5rem;
      width: 100%;
      padding: 6rem;
      overflow: hidden; }
  @media only screen and (max-width: 990px) {
    .landing-tab-container .landing-tab-wrapper .sidebar-container {
      right: 0;
      z-index: 100;
      height: auto;
      border-radius: 5rem; }
      .landing-tab-container .landing-tab-wrapper .sidebar-container ul .logout-container {
        position: static; }
        .landing-tab-container .landing-tab-wrapper .sidebar-container ul .logout-container .logout {
          font-size: 2.5rem;
          color: #ffadad; }
          .landing-tab-container .landing-tab-wrapper .sidebar-container ul .logout-container .logout svg {
            font-size: 4.9rem;
            padding-right: 1rem;
            color: #ffadad; }
    .landing-tab-container .landing-tab-wrapper .page-content-wrapper {
      margin-left: 0; }
      .landing-tab-container .landing-tab-wrapper .page-content-wrapper .content {
        overflow: scroll; } }
  @media screen and (min-width: 990px) and (max-height: 660px) {
    .landing-tab-container .landing-tab-wrapper .sidebar-container ul .logout-container {
      position: static; } }

.property-financial-container {
  height: 100%; }
  .property-financial-container .operation-bar {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    .property-financial-container .operation-bar .filterbar {
      display: flex;
      flex-wrap: wrap; }
      .property-financial-container .operation-bar .filterbar .payment-category-wrapper {
        background: #fff;
        border-radius: 1rem;
        margin-right: 1.5rem;
        margin-bottom: 1.5rem;
        height: 5.5rem; }
        .property-financial-container .operation-bar .filterbar .payment-category-wrapper .pmt-cat-header {
          font-size: 1.2rem;
          padding: 0.5rem 0 0 0.5rem; }
        .property-financial-container .operation-bar .filterbar .payment-category-wrapper .select-box {
          border-radius: 0rem 0rem 1rem 1rem;
          height: 3rem; }
        @media screen and (max-width: 600px) {
          .property-financial-container .operation-bar .filterbar .payment-category-wrapper {
            width: 100%;
            height: 8rem;
            font-size: 2.7rem; }
            .property-financial-container .operation-bar .filterbar .payment-category-wrapper .pmt-cat-header {
              font-size: 1.6rem;
              padding: 0.7rem 0 0 0.7rem; } }
        @media screen and (min-width: 600px) and (max-width: 992px) {
          .property-financial-container .operation-bar .filterbar .payment-category-wrapper {
            height: 8rem;
            font-size: 2.7rem; }
            .property-financial-container .operation-bar .filterbar .payment-category-wrapper .pmt-cat-header {
              font-size: 1.7rem;
              padding: 0.7rem 0 0.3rem 0.7rem; } }
      .property-financial-container .operation-bar .filterbar .month-year-picker {
        width: 29rem;
        height: 5.5rem;
        background-color: #fff;
        border: none;
        outline: 0;
        margin-right: 1.5rem;
        font-size: 2rem;
        font-weight: bold;
        color: #000;
        padding: 0 1rem;
        border-radius: 1rem;
        margin-bottom: 2rem;
        outline: none;
        box-shadow: none; }
        @media screen and (max-width: 1199px) {
          .property-financial-container .operation-bar .filterbar .month-year-picker {
            flex: 1;
            height: 8rem;
            font-size: 2.7rem; } }
    .property-financial-container .operation-bar .searchbar {
      width: 75rem; }
      .property-financial-container .operation-bar .searchbar .input-group {
        flex-wrap: nowrap; }
        .property-financial-container .operation-bar .searchbar .input-group .form-outline {
          width: 100%; }
          .property-financial-container .operation-bar .searchbar .input-group .form-outline input {
            background-color: #fff; }
      .property-financial-container .operation-bar .searchbar button {
        width: 5rem; }
        .property-financial-container .operation-bar .searchbar button svg {
          font-size: 2rem; }
      .property-financial-container .operation-bar .searchbar i {
        color: #fff;
        font-size: 2rem;
        font-weight: bold;
        padding: 0 2rem; }
      @media screen and (max-width: 992px) {
        .property-financial-container .operation-bar .searchbar {
          flex: 1; }
          .property-financial-container .operation-bar .searchbar .input-group {
            flex-wrap: nowrap;
            width: 97%; }
            .property-financial-container .operation-bar .searchbar .input-group input {
              height: 8rem;
              font-size: 2.2rem; }
            .property-financial-container .operation-bar .searchbar .input-group button {
              width: 8rem; }
              .property-financial-container .operation-bar .searchbar .input-group button svg {
                font-size: 3rem; } }
  .property-financial-container .spacious-table {
    height: 100%;
    overflow-y: scroll;
    height: calc(100vh - 45rem); }
    .property-financial-container .spacious-table .table-header-container {
      display: flex;
      width: 100%;
      justify-content: space-between;
      position: sticky;
      top: 0; }
      .property-financial-container .spacious-table .table-header-container .table-header {
        font-size: 2rem;
        font-weight: 600;
        color: #000;
        padding: 2.5rem;
        border-top: 0;
        display: flex;
        justify-content: center;
        background-color: #f5f6fa;
        width: 20%;
        min-width: 25rem;
        border-bottom: 1px solid #bdbdbd; }
        @media screen and (max-width: 992px) {
          .property-financial-container .spacious-table .table-header-container .table-header {
            font-size: 2.5rem; } }
    .property-financial-container .spacious-table .rows-container {
      width: 100%; }
      .property-financial-container .spacious-table .rows-container .table-rows {
        display: flex;
        width: 100%;
        justify-content: space-between;
        background-color: #fff;
        margin: 2rem 0;
        border-radius: 1rem; }
        .property-financial-container .spacious-table .rows-container .table-rows .table-row {
          font-size: 2rem;
          padding: 4.5rem;
          display: flex;
          justify-content: center;
          border: 0;
          width: 20%;
          min-width: 25rem;
          background-color: #fff; }
          .property-financial-container .spacious-table .rows-container .table-rows .table-row img {
            width: 55%; }
          @media screen and (max-width: 992px) {
            .property-financial-container .spacious-table .rows-container .table-rows .table-row {
              font-size: 2.5rem; } }

.property-details-container .property-details-wrapper .first-col .profile-img {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  background: #f1f3e6;
  border-radius: 100%; }
  .property-details-container .property-details-wrapper .first-col .profile-img .profile-text {
    position: absolute;
    font-size: 8rem;
    text-transform: capitalize;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0; }
  @media screen and (max-width: 700px) {
    .property-details-container .property-details-wrapper .first-col .profile-img {
      width: 70%;
      padding-bottom: 70%; } }
  @media screen and (min-width: 700px) and (max-width: 1199px) {
    .property-details-container .property-details-wrapper .first-col .profile-img {
      width: 50%;
      padding-bottom: 50%; } }

.property-details-container .property-details-wrapper .first-col ul {
  list-style: none;
  margin-top: 3rem; }
  .property-details-container .property-details-wrapper .first-col ul .simple-link {
    font-size: 1.8rem;
    font-weight: 600;
    color: #0865de;
    margin: 0.5rem; }
    .property-details-container .property-details-wrapper .first-col ul .simple-link svg {
      font-size: 2.5rem;
      margin-right: 0.3rem; }
    @media screen and (max-width: 992px) {
      .property-details-container .property-details-wrapper .first-col ul .simple-link {
        padding: 1rem;
        font-size: 2.2rem; }
        .property-details-container .property-details-wrapper .first-col ul .simple-link svg {
          font-size: 2.8rem; } }

.property-details-container .property-details-wrapper .second-col .form-group {
  padding: 1rem 4rem; }
  @media screen and (max-width: 992px) {
    .property-details-container .property-details-wrapper .second-col .form-group {
      padding: 1rem 0rem; } }

.property-details-container .property-details-wrapper .third-col .form-group {
  padding: 1rem; }

.property-details-container .property-details-wrapper .third-col .bank-details input {
  margin-bottom: 1rem; }

.property-details-container .pdetails-button-wrapper {
  padding: 0rem 4rem; }
  .property-details-container .pdetails-button-wrapper button {
    width: 35%; }
  @media screen and (max-width: 700px) {
    .property-details-container .pdetails-button-wrapper {
      padding: 0rem; }
      .property-details-container .pdetails-button-wrapper button {
        width: 100%; } }

.room-details-container .compact-table {
  overflow-y: scroll;
  height: calc(100vh - 40rem); }
  .room-details-container .compact-table .table-header-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: #f5f6fa; }
    .room-details-container .compact-table .table-header-container .table-header {
      font-size: 2rem;
      font-weight: 600;
      color: #000;
      padding: 1.5rem;
      border-top: 0;
      display: flex;
      flex: 1;
      justify-content: flex-start;
      align-items: center;
      background-color: #f5f6fa;
      min-width: 30rem;
      border-bottom: 1px solid #bdbdbd; }
      @media screen and (max-width: 992px) {
        .room-details-container .compact-table .table-header-container .table-header {
          font-size: 2.5rem; } }
      .room-details-container .compact-table .table-header-container .table-header i {
        margin-right: 0.7rem; }
      .room-details-container .compact-table .table-header-container .table-header svg {
        color: #ed303b;
        font-size: 2.3rem;
        margin-right: 0.5rem; }
    .room-details-container .compact-table .table-header-container .move-in {
      color: #ed303b; }
    .room-details-container .compact-table .table-header-container .move-out {
      color: #0865de; }
  .room-details-container .compact-table .rows-container {
    width: 100%; }
    .room-details-container .compact-table .rows-container .table-rows {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin: 0.5rem 0; }
      .room-details-container .compact-table .rows-container .table-rows .table-row {
        border-top: 1px solid #bdbdbd;
        font-size: 2rem;
        padding: 2rem;
        display: flex;
        flex: 1;
        justify-content: flex-start;
        min-width: 30rem; }
        @media screen and (max-width: 992px) {
          .room-details-container .compact-table .rows-container .table-rows .table-row {
            font-size: 2.5rem; } }

.support-center-container .support-center-wrapper .left-col {
  padding-right: 5rem; }
  .support-center-container .support-center-wrapper .left-col .sub-header {
    font-size: 2.8rem;
    font-weight: 900;
    color: #0865de;
    margin: 3rem 0; }
  .support-center-container .support-center-wrapper .left-col .support-form .form-area-input {
    height: 19rem;
    width: 100%;
    font-size: 2rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #bdbdbd;
    padding: 1rem 2.8rem;
    outline: 0;
    border-radius: 0.5rem; }
    @media screen and (max-width: 800px) {
      .support-center-container .support-center-wrapper .left-col .support-form .form-area-input {
        font-size: 3rem !important; } }
  .support-center-container .support-center-wrapper .left-col .support-form .form-file-upload {
    height: 10rem;
    width: 100%;
    font-size: 2rem;
    color: #000;
    padding: 1rem 2.8rem;
    outline: 0;
    border-radius: 0.5rem; }
  .support-center-container .support-center-wrapper .left-col .support-form .button-wrapper {
    width: 30%;
    margin: 2rem 0 3rem 0; }
    @media screen and (max-width: 700px) {
      .support-center-container .support-center-wrapper .left-col .support-form .button-wrapper {
        width: 100%; } }

.support-center-container .support-center-wrapper .right-col .ticket-status-container {
  margin-bottom: 2rem;
  padding: 3rem 5rem;
  border-radius: 1rem;
  background-color: #fff; }
  .support-center-container .support-center-wrapper .right-col .ticket-status-container .ticket-status-header {
    font-size: 2rem;
    font-weight: bold;
    color: #0865de;
    margin-bottom: 2rem; }
    @media screen and (max-width: 992px) {
      .support-center-container .support-center-wrapper .right-col .ticket-status-container .ticket-status-header {
        font-size: 2.7rem; } }
  .support-center-container .support-center-wrapper .right-col .ticket-status-container .ticket-list-container {
    height: 16vh;
    padding-bottom: 2rem;
    overflow-y: scroll; }
  .support-center-container .support-center-wrapper .right-col .ticket-status-container .no-tickets {
    font-size: 1.8rem; }

@media screen and (max-width: 800px) {
  .support-center-container .support-center-wrapper .left-col {
    padding-right: 0rem; } }

.home-container {
  height: calc(100vh - 23rem);
  overflow-y: scroll; }
  .home-container .username-wrapper {
    font-size: 2rem;
    color: #000;
    margin-top: -0.7rem;
    margin-bottom: 2rem; }
    .home-container .username-wrapper .username {
      font-weight: 600;
      text-transform: capitalize; }
  .home-container .home-wrapper .left-col .kpi-list {
    display: flex; }
  .home-container .home-wrapper .right-col {
    padding-right: 5rem; }
    .home-container .home-wrapper .right-col .metrics-container {
      border-radius: 2.7rem;
      background: #fff;
      display: flex;
      justify-content: center; }
      .home-container .home-wrapper .right-col .metrics-container .occupancy-container {
        padding: 3rem; }
        .home-container .home-wrapper .right-col .metrics-container .occupancy-container .occupancy-header {
          font-size: 2rem;
          font-weight: bold;
          color: #252e48;
          text-align: center;
          padding-bottom: 3rem; }
        .home-container .home-wrapper .right-col .metrics-container .occupancy-container .metric-container {
          margin: 5rem 0 1rem 0; }
          .home-container .home-wrapper .right-col .metrics-container .occupancy-container .metric-container .metric-wrapper {
            display: flex;
            justify-content: center;
            margin-bottom: 2rem; }
            .home-container .home-wrapper .right-col .metrics-container .occupancy-container .metric-container .metric-wrapper .metric .metric-text {
              font-size: 2rem;
              font-weight: bold;
              color: #000;
              text-align: center; }
            .home-container .home-wrapper .right-col .metrics-container .occupancy-container .metric-container .metric-wrapper .metric .metric-value {
              font-size: 4rem;
              font-weight: bold;
              color: #ed303b;
              text-align: center; }
    .home-container .home-wrapper .right-col .movein-moveout-container {
      border-radius: 2.7rem;
      margin-top: 3rem;
      background: #fff;
      padding: 3rem; }
      .home-container .home-wrapper .right-col .movein-moveout-container .movein-moveout .metric-wrapper {
        display: flex;
        justify-content: center;
        margin-bottom: 2rem; }
        .home-container .home-wrapper .right-col .movein-moveout-container .movein-moveout .metric-wrapper .metric .metric-text {
          font-size: 2rem;
          font-weight: bold;
          color: #000;
          text-align: center; }
        .home-container .home-wrapper .right-col .movein-moveout-container .movein-moveout .metric-wrapper .metric .metric-value {
          font-size: 4rem;
          font-weight: bold;
          color: #000;
          text-align: center; }
  @media screen and (max-width: 600px) {
    .home-container {
      overflow-y: unset; }
      .home-container .home-wrapper .left-col .kpi-list {
        display: flex;
        flex-direction: column;
        padding: 0rem; }
      .home-container .home-wrapper .right-col {
        padding-right: 0rem; } }
  @media screen and (min-width: 600px) and (max-width: 992px) {
    .home-container .home-wrapper .left-col .kpi-list {
      display: flex;
      flex-direction: column;
      padding-right: 4rem; }
    .home-container .home-wrapper .right-col {
      padding-right: 0rem; } }

.page-not-found-container {
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%; }

@font-face {
  font-family: 'icomoon';
  src: url("./assets/fonts/icomoon.eot?2rkkxc");
  src: url("./assets/fonts/icomoon.eot?2rkkxc#iefix") format("embedded-opentype"), url("./assets/fonts/icomoon.ttf?2rkkxc") format("truetype"), url("./assets/fonts/icomoon.woff?2rkkxc") format("woff"), url("./assets/fonts/icomoon.svg?2rkkxc#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-prop-inventory:before {
  content: "\e900";
  color: #fff; }

.icon-prop-details:before {
  content: "\e902";
  color: #fff; }

.icon-logout:before {
  content: "\e903";
  color: #ffadad; }

.icon-room .path1:before {
  content: "\e904";
  color: #ed303b; }

.icon-room .path2:before {
  content: "\e905";
  margin-left: -1.04296875em;
  color: #ed303b; }

.icon-room .path3:before {
  content: "\e906";
  color: #ed303b; }

.icon-rupee:before {
  content: "\e907";
  color: #ed303b; }

.icon-move_in:before {
  content: "\e908";
  color: #ed303b; }

.icon-move_out:before {
  content: "\e909";
  color: #0865de; }

.icon-room_status:before {
  content: "\e90a";
  color: #ed303b; }

.icon-home:before {
  content: "\e90b";
  color: #fff; }

.icon-support:before {
  content: "\e90c";
  color: #fff; }

.icon-bell:before {
  content: "\e90d"; }

@media screen and (min-width: 300px) and (max-width: 374px) {
  html {
    font-size: 6px !important; } }

@media screen and (min-width: 375px) and (max-width: 767px) {
  html {
    font-size: 6px !important; } }

@media screen and (min-width: 768px) and (max-width: 992px) {
  html {
    font-size: 6px !important; } }

@media screen and (min-width: 993px) and (max-width: 1219px) {
  html {
    font-size: 6px !important; } }

@media screen and (min-width: 1220px) and (max-width: 1365px) {
  html {
    font-size: 7px !important; } }

@media screen and (min-width: 1365px) and (max-width: 1919px) {
  html {
    font-size: 8px !important; } }

@media screen and (min-width: 1920px) {
  html {
    font-size: 10px !important; } }
